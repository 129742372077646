import React from 'react'
import Layout from '../components/layout'
import PageHeading from '../UI/PageHeading/PageHeading'
import PageTransition from 'gatsby-plugin-page-transitions'
import { Text } from './../UI/Typing'
import Animotion from './../UI/Animotion'
import { FormattedMessage } from 'gatsby-plugin-intl'
import './capodanno.styl'

import HeadImg from './../Assets/images_pg/cook/capodanno.jpeg'
import PrimoImg from './../Assets/images_pg/cook/006.jpg'
import SecondoImg from './../Assets/images_pg/cook/cook1.jpg'
import DolceImg from './../Assets/images_pg/cook/DSC_0795.jpg'

const CookRow = props => (
  <div className={`CookRow ${props.position}`}>
    <div>
      <figure style={{ backgroundImage: `url(${props.image})` }} />
    </div>
    <div>
      <h3>{props.title}</h3>
      <Text>{props.text}</Text>
    </div>
  </div>
)

const CapodannoPage = ({ pageContext: { locale } }) => (
  <Layout locale={locale}>
    <PageTransition>
      <PageHeading
        image={HeadImg}
        title={<FormattedMessage id='capodannoTitle' />}
        subtitle={<FormattedMessage id='capodannoSubtitle' />}
        text={<FormattedMessage id='capodannoDescription' />}
      />
      <section className='contentMenu'>
        <ul>
          <li>
            <h3>Antipasti</h3>
            <Text>
              Saluto della Cucina<br />
              Rosa di Gorizia saltata con mele e cren<br />
              Tartara di Fassona Piemontese tagliata a coltello con crackers fatti in casa e riduzione di uovo<br />
            </Text>
          </li>
          <li>
            <h3>Primi piatti</h3>
            <Text>
              Crema di patate con Tartufo Bianco<br />
              Tagliolini alle Creste<br />
            </Text>

          </li>
          <li>
            <h3>Secondo piatto</h3>
            <Text>
              Costine di Manzetta Prussiana a cottura lenta in Peka con purè di sedano rapa e riduzione al nostro vino Rosso
            </Text>

          </li>
          <li>
            <h3>I dessert</h3>
            <Text>
              Cremoso al cioccolato bianco, gianduia e lampone<br />
              Brindisi di mezzanotte con panettone, pandoro, crema al mandarino e torrone fatto in casa
            </Text>

          </li>
          <li>
            <Text>
              Balli scatenati con DJ e intermezzo della tradizione con Muset e lenticchie!
            </Text><br /><br /><br />
            <h3>Buon 2020 a tutti!</h3>
            <small>Il menù potrà subire delle variazioni in base alla reperibilità dei prodotti</small>
          </li>
        </ul>

      </section>
    </PageTransition >
  </Layout >
)

export default CapodannoPage
